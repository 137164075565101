import $ from 'jquery'

export function changeMainColor(defaultTheme) {
    $(".mainColor").css("color", defaultTheme)
    $(".mainBgColor").css("background", defaultTheme)
    $(".mainBdColor").css("border-color", defaultTheme)
    $(".resetColor").css({"color": "#000000", "background": "transparent"})
    $(".resetBdColor").css({"border-color": "#F7F7F7"})
    $(".resetIconColor").css({"color": "#A6A6A6"})
    $(".whiteColor").css({"color": "#ffffff"})
}

/**
 * 获取页面链接参数
 * @param {*} str 参数名称
 */
export function QueryString(str) {
    var sValue = window.location.href.match(new RegExp("[\?\&]" + str + "=([^\&]*)(\&?)", "i"));
    return sValue ? sValue[1] : sValue;
}

/**
 * 是否有权限
 * @param {*} key
 */
export function hasPermission(key) {
    let arr = JSON.parse(sessionStorage.getItem('permissions') || '[]')
    if (arr.indexOf('*') !== -1 || arr.indexOf(key) !== -1) {
        return true
    } else {
        return false
    }
}

export function hasPermissionButton(key) {
    let arr = JSON.parse(sessionStorage.getItem('permissions') || '[]')
    if (arr.indexOf(key) !== -1) {
        return true
    } else {
        return false
    }
}

/**
 * 表单对象赋值:
 * 对目标对象存在且源对象同样存在的属性，全部覆盖；
 * 目标对象不存在但是源对象存在的属性， 全部丢弃；
 * 目标对象存在但是源对象不存在的属性，如果是字符串赋值为空串，其余类型赋值为undefined
 */
export function recover(target, source) {
    if (target === undefined || target === null) {
        throw new TypeError('Cannot convert first argument to object')
    }
    var to = Object(target)
    if (source === undefined || source === null) {
        return to
    }
    var keysArray = Object.keys(Object(target))
    for (var nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex++) {
        var nextKey = keysArray[nextIndex]
        var desc = Object.getOwnPropertyDescriptor(target, nextKey)
        if (desc !== undefined && desc.enumerable) {
            if (to[nextKey] instanceof Array) {
                to[nextKey] = source[nextKey]
            } else if (to[nextKey] instanceof Object) {
                recover(to[nextKey], source[nextKey])
            } else if (source[nextKey] !== undefined) {
                to[nextKey] = source[nextKey]
            } else if (typeof (to[nextKey]) === 'string') {
                to[nextKey] = ''
            } else {
                to[nextKey] = undefined
            }
        }
    }
    return to
}

export function exportExcel(url, params, fileName, methodType = 'get') {
    this.$axios(url, params, methodType, 'blob').then(response => {
        let blob = new Blob([response.data], {type: 'application/vnd.ms-excel'})
        let link = document.createElement('a')
        link.download = decodeURI(fileName)
        link.href = window.URL.createObjectURL(blob)
        link.click()
    }).catch((error) => {

    })
}

export function getFileIcon(file) {
    if (file == 'txt') {
        return require("@/assets/img/txtFileIcon.png")
    }
    if (file == 'mp4') {
        return require("@/assets/img/mp4FileIcon.png")
    }
    if (file == 'mp3') {
        return require("@/assets/img/mp3FileIcon.png")
    }
    if (file == 'doc' || file == 'docx') {
        return require("@/assets/img/wordFileIcon.png")
    }
    if (file == 'xls' || file == 'xlsx') {
        return require("@/assets/img/xlsxFileIcon.png")
    }
    if (file == 'pdf') {
        return require("@/assets/img/pdfFileIcon.png")
    }
    if (file == 'jpg' || file == 'png') {
        return require("@/assets/img/imgFileIcon.png")
    }
}

export function formatDate(value, type) {
    let date = new Date(value);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? ('0' + MM) : MM;
    let d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    let h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    let m = date.getMinutes();
    m = m < 10 ? ('0' + m) : m;
    let s = date.getSeconds();
    s = s < 10 ? ('0' + s) : s;
    if (type == 1) {
        return y + '-' + MM + '-' + d;
    } else if (type == 2) {
        return y + '年' + MM + '月' + d + '日';
    } else if (type == 3) {
        return y + '-' + MM + '-' + d + ' ' + '23:59:59'
    } else if (type == 5) {
        return h + ':' + m + ':' + s;
    } else {
        return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
    }
}

// 资源采集支持预览的文件格式
export function getResourcePreviewFileFormat(type) {
    if (type == 1) {
        return ['gif', 'jpg', 'jpeg', 'png', 'bmp', 'GIF', 'JPG', 'PNG', 'JPEG']
    }
    if (type == 2) {
        return ['mp3', 'wav']
    }
    if (type == 3) {
        return ['mp4', 'flv']
    }
    if (type == 4 || type == 6 || type == 5) {
        return ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf']
    }
    if (type == 7) {
        return ['gif', 'jpg', 'jpeg', 'png', 'bmp', 'mp3', 'wav', 'mp4', 'flv', 'doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf']
    }
}

// 资源采集上传文件格式
export function getResourceUploadFileFormat(type) {
    if (type == 0) {
        return ['stl', 'zip', '7z', 'rar']
    }
    if (type == 1) {
        return ['gif', 'jpg', 'jpeg', 'png', 'bmp', 'tif', 'zip', '7z', 'rar']
    }
    if (type == 2) {
        return ['mp3', 'ogg', 'm4a', 'flac', 'opus', 'wav', 'wma', 'zip', '7z', 'rar']
    }
    if (type == 3) {
        return ['mp4', 'mkv', 'avi', 'wmv', 'rmvb', '3gp', 'asf', 'swf', 'vob', 'mov', 'm4v', 'flv', 'f4v', 'mts', 'ts', 'zip', '7z', 'rar']
    }
    if (type == 4 || type == 6 || type == 5) {
        return ['doc', 'docx', 'docm', 'dotx', 'dotm', 'ppt', 'pptx', 'pptm', 'ppsx', 'ppsm', 'potx', 'potm', 'xls', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlam', 'pdf', 'zip', '7z', 'rar']
    }
    if (type == 7) {
        return ['gif', 'jpg', 'jpeg', 'png', 'bmp', 'tif', 'stl', 'zip', '7z', 'rar', 'mp3', 'ogg', 'm4a', 'flac', 'opus', 'wav', 'wma', 'mp4', 'mkv', 'avi', 'wmv', 'rmvb', '3gp', 'asf', 'swf', 'vob', 'mov', 'm4v', 'flv', 'f4v', 'mts', 'ts', 'doc', 'docx', 'docm', 'dotx', 'dotm', 'ppt', 'pptx', 'pptm', 'ppsx', 'ppsm', 'potx', 'potm', 'xls', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlam', 'pdf']
    }
}

// 资源采集上传文件暂不支持格式
export function getUploadNotCurrentlySupported(type) {
    if (type == 2) {
        return ['cd', 'asf', 'mp3pro', 'rm', 'real', 'ape', 'module', 'midi', 'vqf']
    }
    if (type == 3) {
        return ['mpeg1', 'mpeg2', 'mpeg4', 'dat']
    }
    if (type == 4 || type == 6 || type == 5) {
        return ['ppam', 'xlsb']
    }
    if (type == 7) {
        return ['cd','asf','mp3pro','rm','real','ape','module','midi','vqf','mpeg1','mpeg2','mpeg4','dat','ppam','xlsb']
    }
}

// 根据文件格式获取图标
export function getFileFormatIcon(fileFormat) {
    let file = fileFormat.toLocaleLowerCase()
    let threeFormat = ['gltf', 'mtl', 'obj', 'stl']
    let imageFormat = ['gif', 'jpg', 'jpeg', 'png', 'bmp', 'tif']
    let audioFormat = ['mp3', 'ogg', 'm4a', 'flac', 'opus', 'wav', 'wma']
    let videoFormat = ['mp4', 'mkv', 'avi', 'wmv', 'rmvb', '3gp', 'asf', 'swf', 'vob', 'mov', 'm4v', 'flv', 'f4v', 'mts', 'ts']
    let pdfFormat = ['pdf']
    let pptFormat = ['ppt', 'pptx', 'pptm', 'ppsx', 'ppsm', 'potx', 'potm']
    let wordFormat = ['doc', 'docx', 'docm', 'dotx', 'dotm']
    let xlsxFormat = ['xls', 'xlsx', 'xlsm', 'xltx', 'xltm', 'xlam']
    let zipFormat = ['zip', '7z', 'rar']
    if (threeFormat.indexOf(file) > -1) {
        return require('@/assets/img/threeFormatIcon.png')
    }
    if (imageFormat.indexOf(file) > -1) {
        return require('@/assets/img/imageFormatIcon.png')
    }
    if (audioFormat.indexOf(file) > -1) {
        return require('@/assets/img/audioFormatIcon.png')
    }
    if (videoFormat.indexOf(file) > -1) {
        return require('@/assets/img/videoFormatIcon.png')
    }
    if (pdfFormat.indexOf(file) > -1) {
        return require('@/assets/img/pdfFormatIcon.png')
    }
    if (pptFormat.indexOf(file) > -1) {
        return require('@/assets/img/pptFormatIcon.png')
    }
    if (wordFormat.indexOf(file) > -1) {
        return require('@/assets/img/wordFormatIcon.png')
    }
    if (xlsxFormat.indexOf(file) > -1) {
        return require('@/assets/img/xlsxFormatIcon.png')
    }
    if (zipFormat.indexOf(file) > -1) {
        return require('@/assets/img/zipFormatIcon.png')
    }
}

// 元数据时间格式化
export function metadataDateFormatValue(item, val) {
    let date = val
    if (val && item.dataFormat == 'yyyyMMdd' && val.indexOf('-') > -1) {
        let arr = val.split(' ')[0].split('-')
        date = arr[0] + arr[1] + arr[2]
    }
    if (val && item.dataFormat == 'yyyy-MM-dd' || val && !item.dataFormat) {
        date = val.split(' ')[0]
    }
    return date
}

export function zlzsExportExcel (url, params) {
    let log={
        operModular:"1",//模块
        operType:"18",//类型
        operTerm:"导出目录",//内容
        operSystem:"1",//结果
    }
    this.$axios(url, {exhibitionId:params},'get','blob').then(response => {
        // ajax.get(url,{
        //   responseType: 'blob',
        //   dataType : "jsonp",
        // }).then(({ data }) => {
        //   console.log(response)
        //   // 为了简单起见这里blob的mime类型 固定写死了
        if(response.data){
            log.operSystem="0";
            let blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
            let filename = response.headers['content-disposition']
            let link = document.createElement('a')
            link.download = decodeURI(filename)
            link.href = window.URL.createObjectURL(blob)
            link.click()
        }else{
            log.operSystem="1";
        }
        // this.$axios(this.api.common.logSave,log,'post').then(data => {
        //
        // })

        // }).catch(error => {
        //   console.error(error)
        // })


        // if (!response) {
        //   return
        // }
        // // console.log(response);
        // let link = document.createElement('a')
        // link.href = window.URL.createObjectURL(new Blob([response],{ type: 'application/vnd.ms-excel' }))
        // link.target = '_blank'
        // let filename = response.headers['Content-Disposition']
        // link.download = decodeURI(filename)
        // document.body.appendChild(link)
        // link.click()
        // document.body.removeChild(link)
        // eslint-disable-next-line handle-callback-err
    }).catch((error) => {

    })

}


export default {
    getFileFormatIcon,
    metadataDateFormatValue,
    getResourcePreviewFileFormat,
    getResourceUploadFileFormat,
    getUploadNotCurrentlySupported,
    getFileIcon,
    hasPermission,
    hasPermissionButton,
    recover,
    exportExcel,
    QueryString,
    changeMainColor,
    formatDate,
    zlzsExportExcel
}
